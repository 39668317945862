import React, { ReactText, useCallback, useContext, useState } from 'react';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import { Container } from './style';
import Loja from '~/components/Loja';
import { getUserData } from '~/services/user';
import { Col, Row } from 'react-bootstrap';
import {
  InputDate,
  InputMoney,
  InputNumber,
  InputSelect,
  InputAsyncSelect,
  InputTime,
} from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import BuscaPdvLoja from '~/components/BuscaPdvLoja';
import BuscaOperadoresLoja from '~/components/BuscaOperadoresLoja';
import apiLancamento from './services/api';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaLancamento } from './validations';
import { GridRowParams } from '@material-ui/data-grid';
import moment from 'moment';
import { FormState, SelectedProps } from './protocols';
import { moneyFormat } from '../../utils/functions';
import { transformAsCurrency } from '~/utils/functions';
import { LojaContext } from '~/context/loja';

const operacaoData = [
  {
    value: 0,
    label: 'ABERTURA',
  },
  {
    value: 1,
    label: 'FECHAMENTO',
  },
  {
    value: 2,
    label: 'REFORÇO',
  },
  {
    value: 3,
    label: 'SANGRIA',
  },
];

const LancamentoCaixa: React.FC = () => {
  const {
    register,
    control,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaLancamento) });
  const user = getUserData();
  const { loja } = useContext(LojaContext);
  const [lojas, setLojas] = useState<any>(user.loja);
  const [init, setInit] = useState<boolean>(false);
  const [codSeqMov, setCodSeqMov] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [operacao, setOperacao] = useState<any>();
  const [tipoIntegraFrente, setTipoIntegraFrente] = useState<any>();
  const [formState, setFormState] = useState<FormState>({
    caixa: {
      value: '',
      label: '',
      isInvalid: true,
      isRequired: true,
    },
    operador: {
      value: '',
      label: '',
      isInvalid: true,
      isRequired: true,
    },
  });
  const returnSeach = (): void => {
    setShowSearch(!showSearch);
    resetData();
  };

  const resetData = (): void => {
    setLojas(user.loja);
    setFormState({
      caixa: {
        value: '',
        label: '',
        isInvalid: true,
        isRequired: true,
      },
      operador: {
        value: '',
        label: '',
        isInvalid: true,
        isRequired: true,
      },
    });
    reset({
      dta_movimento: '',
      hra_movimento: '',
      valor: '',
      num_cupom: '',
      operacao: '',
    });
    setOperacao('');
    setIsUpdate(false);
    setInit(false);
  };
  const onSave = async (): Promise<ReactText> => {
    const requestData = {
      cod_loja: lojas,
      dta_movimento: getValues('dta_movimento'),
      num_pdv: Number(formState.caixa.value),
      cod_operador: formState.operador.value,
      hra_mov: getValues('hra_movimento'),
      val_mov: transformAsCurrency(getValues('valor')),
      num_cupom: Number(getValues('num_cupom')),
      cod_finalizadora:
        getValues('operacao').value !== 3
          ? ''
          : getValues('finalizadora').cod_finalizadora,
      cod_tipo_operacao: getValues('operacao').value,
      tipo_integra_frente:
        loja.cod_loja === lojas ? loja.tipo_integra_frente : tipoIntegraFrente,
    };
    if (!isUpdate) {
      const response = await apiLancamento.create(requestData);
      resetData();
      return toast.success(response);
    }
    const response = await apiLancamento.update(requestData, codSeqMov);
    resetData();
    setShowSearch(true);
    return toast.success(response);
  };

  const onRowClick = (param: GridRowParams): void => {
    const { row } = param;
    const {
      cod_seq_mov,
      cod_loja,
      num_pdv,
      dta_movimento,
      hra_mov_view,
      cod_operador,
      des_operador,
      val_mov,
      tipo_operacao,
      des_operacao,
      cod_finalizadora,
      des_finalizadora,
      num_cupom,
    } = row;
    setCodSeqMov(cod_seq_mov);
    setLojas(cod_loja);
    setFormState({
      caixa: {
        value: num_pdv,
        label: num_pdv,
        isInvalid: false,
        isRequired: true,
      },
      operador: {
        value: cod_operador,
        label: des_operador,
        isInvalid: false,
        isRequired: true,
      },
    });
    setValue('dta_movimento', moment(dta_movimento).utc().format('YYYY-MM-DD'));
    setValue('hra_movimento', hra_mov_view);
    setValue('valor', moneyFormat(val_mov));
    setValue('operacao', {
      value: tipo_operacao,
      label: des_operacao,
    });
    setOperacao(des_operacao);
    setValue('num_cupom', num_cupom);
    setIsUpdate(true);
    setShowSearch(false);
    if (tipo_operacao === 3) {
      setValue('finalizadora', {
        cod_finalizadora,
        des_finalizadora,
        label: des_finalizadora,
      });
    }
  };

  const onDelete = async (): Promise<void> => {
    const response = await apiLancamento.destroy(codSeqMov);
    toast.success(response);
    resetData();
    setShowSearch(true);
  };

  const regraLoja = useCallback(
    async (cod_loja: number) => {
      if (loja.cod_loja !== cod_loja) {
        const regraLojaFind = await apiLancamento.findRegra(cod_loja);
        setTipoIntegraFrente(regraLojaFind.tipo_integra_frente);
      }
    },
    [loja],
  );

  const validaInputsParaSubmit = (inputs: FormState): boolean => {
    for (let i = 0; i < Object.keys(inputs).length; i++) {
      if (
        formState[Object.keys(inputs)[i] as keyof FormState].isRequired &&
        formState[Object.keys(inputs)[i] as keyof FormState].isInvalid
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={275}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={275}
          title="LANÇAMENTO NO CAIXA"
          codigoRegistro={[
            {
              value: codSeqMov,
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            if (validaInputsParaSubmit(formState)) {
              await handleSubmit(onSave)();
              setInit(true);
            } else {
              await handleSubmit(onSave)();
              setInit(false);
            }
          }}
          isUpdate={isUpdate}
          onCancel={returnSeach}
          onClearFields={resetData}
          onNew={resetData}
          onDelete={onDelete}
          onReturnSearch={returnSeach}
        >
          <Row sm={12} md={12}>
            <Loja
              selectedLoja={lojas}
              isDisabled={isUpdate}
              onChange={(val) => {
                regraLoja(Number(val));
                setLojas(val);
              }}
            />
          </Row>
          <Row sm={12} md={12}>
            <Col sm={12} md={4} lg={3} xl={2}>
              <InputDate
                name="dta_movimento"
                label="Data do Movimento"
                register={register}
                isError={!!errors.dta_movimento}
                control={control}
              />
            </Col>
            <Col sm={12} md={4} lg={3} xl={2}>
              <BuscaPdvLoja
                value={formState.caixa}
                label="PDV - Caixa"
                loja={[lojas]}
                isRequired={formState.caixa.isRequired}
                setInvalid={formState.caixa.isInvalid}
                iniInicializado={init}
                onChange={(e: any) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    caixa: {
                      ...e,
                      isInvalid: false,
                      isRequired: true,
                    },
                  }));
                }}
              />
            </Col>
            <Col sm={12} md={4} lg={6} xl={8}>
              <BuscaOperadoresLoja
                value={formState.operador}
                isRequired={formState.operador.isRequired}
                setInvalid={formState.operador.isInvalid}
                iniInicializado={init}
                loja={[lojas]}
                onChange={(e: any) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    operador: {
                      ...e,
                      isInvalid: false,
                      isRequired: true,
                    },
                  }));
                }}
              />
            </Col>
          </Row>
          <Row sm={12} md={12} className="mt-2">
            <Col sm={12} md={4} lg={3} xl={2}>
              <InputTime
                name="hra_movimento"
                label="Horário"
                register={register}
                control={control}
                isError={!!errors.hra_movimento}
              />
            </Col>
            <Col sm={12} md={4} lg={3} xl={2}>
              <InputMoney
                name="valor"
                label="Valor"
                maxLength={10}
                width="100%"
                placeholder="0,00"
                register={register}
                control={control}
                isError={!!errors.valor}
                min={0}
              />
            </Col>
            <Col sm={12} md={4} lg={3} xl={2}>
              <InputNumber
                name="num_cupom"
                label="Cupom Fiscal"
                placeholder="0"
                register={register}
                control={control}
                isError={!!errors.num_cupom}
                maxLength={8}
                onPaste={(event: any) => {
                  const { clipboardData } = event;
                  const pastedText = clipboardData.getData('text');
                  if (/[a-zA-Z]/.test(pastedText)) {
                    const numericText = pastedText.replace(/[^0-9]/g, '');
                    setValue('num_nf', numericText);
                    event.preventDefault();
                  }
                }}
                onKeyDown={(e: any) => {
                  if ([69].includes(e.keyCode)) {
                    e.preventDefault();
                  }
                }}
                max={99999999}
              />
            </Col>
            <Col
              sm={12}
              md={6}
              lg={3}
              xl={watch('operacao')?.value === 3 ? 3 : 6}
            >
              <InputSelect
                name="operacao"
                label="Operação"
                value={operacao}
                register={register}
                placeholder="Selecione..."
                isError={!!errors.operacao}
                control={control}
                changeSelected={async (option: SelectedProps) => {
                  clearErrors('operacao');
                  clearErrors('finzalizadora');
                  setValue('operacao', option);
                  const { label } = option;
                  setOperacao(label);
                }}
                options={operacaoData}
              />
            </Col>
            {watch('operacao')?.value === 3 && (
              <Col sm={12} md={6} lg={4} xl={3}>
                <InputAsyncSelect
                  label="Finalizadora"
                  maxLength={50}
                  placeholder="Selecione..."
                  name="finalizadora"
                  register={register}
                  isError={!!errors.finalizadora}
                  control={control}
                  changeSelected={(selected) => {
                    clearErrors('finalizadora');
                    setValue('finalizadora', selected);
                  }}
                  api={{
                    route: '/finalizadora',
                    method: 'get',
                    bodyParams: {
                      codLoja: lojas,
                    },
                    fields: ['des_finalizadora'],
                    dependsOf: ['codLoja'],
                    messageForDependsOf: 'É necessário selecionar a loja',
                    searchBeforeFilter: true,
                  }}
                />
              </Col>
            )}
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default LancamentoCaixa;
