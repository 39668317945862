import * as yup from 'yup';

export const schemaLancamento = yup
  .object({
    dta_movimento: yup.string().required(),
    hra_movimento: yup.string().required(),
    valor: yup.string().required(),
    num_cupom: yup.number().required(),
    operacao: yup
      .object({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    finalizadora: yup.object().when('operacao', {
      is: (selected: any) => {
        if (selected?.value === 3) return true;
        return false;
      },
      then: yup
        .object({
          value: yup.string(),
          label: yup.string(),
          cod_finalizadora: yup.number().required(),
          des_finalizadora: yup.string().required(),
        })
        .required(),
      otherwise: yup
        .object({
          value: yup.string(),
          label: yup.string(),
          cod_finalizadora: yup.number(),
          des_finalizadora: yup.string(),
        })
        .notRequired()
        .nullable(true),
    }),
  })
  .required();
