import { toast } from 'react-toastify';

import { ElementIdToMessageMap } from '../protocols';

const elementIdToMessageMap: ElementIdToMessageMap = {
  hash: 'Hash copiado com sucesso',
  url_integracao: 'URL de integração copiada com sucesso',
};

export const copyToClipboard = (elementId: string): void => {
  const copyTextElement: HTMLInputElement | null = document.querySelector(
    `#${elementId}`,
  );
  const successMessage =
    elementIdToMessageMap[elementId] || 'Copiado com sucesso';

  if (!copyTextElement) return;

  copyTextElement.select();
  copyTextElement.setSelectionRange(0, 99999);

  try {
    navigator.clipboard.writeText(copyTextElement.value);
    toast.success(successMessage);
  } catch (err) {
    toast.error('Erro ao copiar para a área de transferência');
  }
};
