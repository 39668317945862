import api from '~/services/api';
import { RequestData } from '../protocols';

const apiLancamento = {
  findRegra: async (codLoja: number): Promise<any> => {
    const { data } = await api.get(`/lojas/regra/${codLoja}`);
    return data.data[0];
  },
  create: async (requestData: RequestData): Promise<string> => {
    const { data } = await api.post('/lancamento-caixa', requestData);
    return data.message;
  },
  update: async (
    requestData: RequestData,
    cod_seq_mov: string,
  ): Promise<string> => {
    const { data } = await api.put(
      `/lancamento-caixa/${cod_seq_mov}`,
      requestData,
    );
    return data.message;
  },
  destroy: async (cod_seq_mov: string): Promise<string> => {
    const { data } = await api.delete(`/lancamento-caixa/${cod_seq_mov}`);
    return data.message;
  },
};

export default apiLancamento;
